import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {charactersLeft, isUCS2, segmentsCount} from 'utils/smsCount';
import {ERROR_STYLE_MSG_LIMIT} from 'utils/sms';
import {Layout, Text} from '@phonectas/unifon-ui-kit';

function MessageLength({message}) {
	const [t] = useTranslation();
	const isUcs2 = isUCS2(message);
	const segments = segmentsCount(message);
	const charsLeft = charactersLeft(message);

	return (
		<Layout justifyContent="end" direction="column" nowrap fullWidth alignItems="end" gap="none">
			<Text
				variant="caption"
				truncate={1}
				color={charsLeft < ERROR_STYLE_MSG_LIMIT ? 'error' : 'textSecondary'}
			>
				{t('{{charsLeft}} left / {{count}} message',
					{
						charsLeft,
						count: segments,

					})}
			</Text>
			{
				isUcs2 && (
					<Layout fullWidth>
						<Text truncate={1} variant="caption" color="textSecondary">
							{t('Message contains Emoji, which halves the maximum message length.')}
						</Text>
					</Layout>
				)
			}
		</Layout>
	);
}

MessageLength.propTypes = {
	message: PropTypes.string,
};

export default MessageLength;
