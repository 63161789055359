import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {IconButton, Layout, Text} from '@phonectas/unifon-ui-kit';
import NewCopyIcon from 'common/components/icons/NewCopyIcon';
import NewOkIcon from 'common/components/icons/NewOkIcon';
import NewWindowIcon from 'common/components/icons/NewWindowIcon';
import {checkResponisble} from 'utils/div';
import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';

function CustomerLinks({data}) {
	const [t] = useTranslation();
	const accDeatails = data?.account_details;
	const respEst = accDeatails?.customer_resp_est;
	const customerResp = accDeatails?.customer_responsible;
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const handleCopy = useCallback((e, value) => {
		e.stopPropagation();
		copyToClipboard(value);
	}, [copyToClipboard]);

	return (
		<Layout
			direction="column"
			fullWidth
			gap="md"
			alignItems="baseline"
		>
			<Layout direction="row" fullWidth justifyContent="space-between" nowrap>
				<Layout direction="row" gap="xs" nowrap fullWidth justifyContent="start">
					<Text variant="body" color="textSecondary">
						{
							`${t('Customer last viewed by')}: `
						}
					</Text>
					<Text variant="body" color="primary">
						{accDeatails?.last_viewed_by || ''}
					</Text>
				</Layout>
				<Layout direction="column" nowrap fullWidth>
					<Layout alignItems="center" justifyContent="end" fullWidth>
						<Text variant="body" color="textSecondary">
							{`${t('Customer responsible')}: ${checkResponisble(customerResp, respEst)}`}
						</Text>
					</Layout>
				</Layout>
			</Layout>
			<Layout direction="row" nowrap gap="md" justifyContent="space-between" fullWidth>
				<Layout gap="none">
					{
						data?.cdr_account_number && (
							<Layout alignItems="center" gap="none">
								<Text variant="body1" style={{fontSize: '18px'}}>{data?.cdr_account_number}</Text>
								<IconButton onClick={(e) => handleCopy(e, data?.cdr_account_number)} size="xs">
									<NewCopyIcon />
								</IconButton>
							</Layout>
						)
					}
					{
						data?.links?.zendesk && (
							<Layout alignItems="center" gap="none">
								<Text variant="body1" style={{fontSize: '18px'}}>Zendesk</Text>
								<IconButton size="xs" disabled>
									<NewWindowIcon color="#9798A9" />
								</IconButton>
							</Layout>
						)
					}
					{
						data?.links?.bedriftsnett && (
							<Layout alignItems="center" gap="none">
								<Text variant="body1" style={{fontSize: '18px'}}>Bedriftsnett</Text>
								<IconButton size="xs" disabled>
									<NewWindowIcon color="#9798A9" />
								</IconButton>
							</Layout>
						)
					}
					{
						data?.links?.connect && (
							<Layout alignItems="center" gap="none">
								<Text variant="body1" style={{fontSize: '18px'}}>{t('Connect')}</Text>
								<IconButton size="xs" disabled>
									<NewWindowIcon color="#9798A9" />
								</IconButton>
							</Layout>
						)
					}
				</Layout>
				<Layout direction="row" style={{gap: '5px', alignContent: 'start'}}>
					<Layout alignItems="center" style={{gap: '5px'}}>
						<NewOkIcon color={accDeatails?.is_vip_customer ? undefined : '#9798A9'} />
						<Text>VIP</Text>
					</Layout>
					<Layout alignItems="center" style={{gap: '5px'}}>
						<NewOkIcon color={accDeatails?.is_major_customer ? undefined : '#9798A9'} />
						<Text>{t('Major customer')}</Text>
					</Layout>
				</Layout>
			</Layout>
		</Layout>
	);
}

CustomerLinks.propTypes = {
	data: PropTypes.object,
};

export default CustomerLinks;
