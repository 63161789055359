import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import Highlighter from 'react-highlight-words';
import {useNavigate} from 'react-router-dom';
import {debounce} from 'lodash';
import {ROUTES} from 'common/constants';


import {
	AutoSuggest,
	CloseIcon,
	Divider,
	IconButton,
	Layout,
	ListItem,
	Page,
	SearchIcon,
	Tabs,
	Text,
} from '@phonectas/unifon-ui-kit';

import HistoryTab from './dashboard/HistoryTab';
import ResponsibleTab from './dashboard/ResponsibleTab';
import {isValidPhone} from 'utils/phoneNumber';
import {serviceStatusesColor} from 'utils/div';

function ThreeSixtyDashboard(props) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const {
		fetchList,
		majorResponsibleList,
		resetAccounts,
		fetchUser,
	} = useThreeSixtyStore();
	const [value, setValue] = useState('');
	const accountsList = useThreeSixtyStore((state) => state.accounts);
	const accounts = accountsList?.accounts;
	const [activeTab, setActiveTab] = useState('1');
	const tabs = useMemo(() => [
		{value: '1', label: t('Major customer').toUpperCase()},
		// {value: '2', label: t('History').toUpperCase()},
		// {value: '3', label: t('Statistics').toUpperCase()},
	], [t]);
	const debouncedFetchList = useMemo(() => debounce((searchValue) => {
		fetchList(10, searchValue, 'serviceStatusAsc');
	}, 500), [fetchList]);

	const handleChange = useCallback((value) => {
		if (typeof value === 'string') {
			setValue(value);
			debouncedFetchList(value);
		} else {
			setValue('');
		}
	}, [debouncedFetchList]);
	const onClick = useCallback((item) => {
		if (isValidPhone(value)) {
			fetchUser(value, item, () => {
				navigate(`${ROUTES.THREE_SIXTY}/${item}`);
				resetAccounts();
			});
		} else {
			navigate(`${ROUTES.THREE_SIXTY}/${item}`);
			resetAccounts();
		}
	}, [navigate, fetchUser, value, resetAccounts]);

	const renderOption = useCallback(({index, style, data: {collection}}) => {
		const item = collection[index];
		const status = item?.user_details?.service_status.toString();

		return (
			<ListItem
				key={index}
				style={style}
				value={item.id}
				onClick={onClick}
				color="primary"
				primaryText={
					<Text variant="h5">
						<Highlighter
							autoEscape
							searchWords={[value]}
							textToHighlight={item?.user_details?.phone_number ? item?.user_details?.phone_number : item.name || item.vat_number}
						/>
					</Text>
				}
				secondaryText={
					<>
						{
							item?.user_details?.phone_number ? (
								<Layout fullWidth justifyContent="space-between" nowrap>
									<Text color="textSecondary" variant="caption">
										{item.name}
									</Text>
									<Text
										style={{
											color: serviceStatusesColor(status).priColor,
										}}
										variant="caption"
									>
										{serviceStatusesColor(status).text}
									</Text>
								</Layout>
							) : (
								<Text color="textSecondary" variant="caption">
									{`${t('VAT')}: ${item.vat_number}`}
								</Text>
							)
						}

					</>
				}
			/>
		);
	}, [value, onClick, t]);
	const handleClearSearch = useCallback(() => {
		setValue('');
	}, []);
	const handleTabSwitch = useCallback((tab) => setActiveTab(tab), []);

	useEffect(() => {
		majorResponsibleList();
	}, [majorResponsibleList]);

	return (
		<Page style={{overflow: 'hidden'}}>
			<Layout style={{rowGap: '5px'}} direction="column" fullWidth>
				<Layout fullWidth alignItems="center" nowrap style={{height: '40px'}}>
					<Text style={{fontSize: '30px'}} variant="h1">Unifon 360</Text>
				</Layout>
			</Layout>
			<Layout direction="column" nowrap fullHeight fullWidth style={{marginTop: '20px', overflow: 'hidden'}}>
				<Layout style={{width: '350px'}}>
					<AutoSuggest
						fullWidth
						value={value}
						placeholder="Search for customer"
						itemSize={50}
						onChange={handleChange}
						// filterBy={['name']}
						disableFiltering
						collection={accounts || []}
						renderItem={renderOption}
						endAdornment={
							!value ? (
								<IconButton>
									<SearchIcon />
								</IconButton>
							) : (
								<IconButton onClick={handleClearSearch}>
									<CloseIcon />
								</IconButton>
							)
						}
						maxDropdownHeight={300}
						inputProps={{autoFocus: 'on', autocomplete: 'off'}}
					/>
				</Layout>
				<Divider dark fullWidth />
				<Tabs
					items={tabs}
					active={activeTab}
					onChange={handleTabSwitch}
				/>
				{activeTab === '1' && <ResponsibleTab />}
				{activeTab === '2' && <HistoryTab />}
				{activeTab === '3' && <div>statistikk</div>}
			</Layout>
		</Page>
	);
}

export default ThreeSixtyDashboard;
