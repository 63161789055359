/* eslint-disable max-len */
export const PLACEHOLDER = {
	USER_NAME: '%username%',
	PHONE_NUMBER: '%phonenumber%',
	COMPANY_NAME: '%companyname%',
};
export const PLACEHOLDER_CHAR_RESERVE = {
	[PLACEHOLDER.USER_NAME]: 15,
	[PLACEHOLDER.PHONE_NUMBER]: 15,
	[PLACEHOLDER.COMPANY_NAME]: 50,
};
export const ERROR_STYLE_MSG_LIMIT = 10;
export const MESSAGE_CHAR_LIMIT_PLAIN = 459;
export const MESSAGE_CHAR_LIMIT_EMOJI = 201;

const GSM_7BIT_REGEXP =
	/^[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]*$/;

const GSM_7BIT_EXT_REGEXP =
	/^[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~\]|€]*$/;
const GSM_7BIT_EXT_CHAR_REGEXP = /^[\^{}\\[~\]|€]$/;
const messageLength = {
	GSM_7BIT: 160,
	GSM_7BIT_EXT: 160,
	UTF16: 70,
};
const multiMessageLength = {
	GSM_7BIT: 153,
	GSM_7BIT_EXT: 153,
	UTF16: 67,
};

const count1 = (text) => {
	const encoding = detectEncoding(text);
	let length = text.length;

	if (encoding === 'GSM_7BIT_EXT') {
		length += countGsm7bitExt(text);
	}

	let characterPerMessage = messageLength[encoding];

	if (length > characterPerMessage) {
		characterPerMessage = multiMessageLength[encoding];
	}

	const messages = Math.ceil(length / characterPerMessage);
	let inCurrentMessage = length;

	if (messages > 0) {
		inCurrentMessage = length - characterPerMessage * (messages - 1);
	}

	let remaining = characterPerMessage * messages - length;

	if (remaining === 0 && messages === 0) {
		remaining = characterPerMessage;
	}

	return {
		encoding,
		length,
		characterPerMessage,
		inCurrentMessage,
		remaining,
		messages,
	};
};

const detectEncoding = (text) => {
	switch (false) {
	case text.match(GSM_7BIT_REGEXP) == null:
		return 'GSM_7BIT';
	case text.match(GSM_7BIT_EXT_REGEXP) == null:
		return 'GSM_7BIT_EXT';
	default:
		return 'UTF16';
	}
};

const countGsm7bitExt = (text) => {
	const extChar = [];

	for (const __char of text) {
		if ((__char.match(GSM_7BIT_EXT_CHAR_REGEXP) || []).length > 0) {
			extChar.push(__char);
		}
	}

	return extChar.length;
};

export {count1 as count};

