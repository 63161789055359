import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import getIconByType from '../../../getIconByType';
import getTitleByType from '../../../getTitleByType';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import {Layout, Text, WarningIcon} from '@phonectas/unifon-ui-kit';
import EventDescription from '../EventDescription';

function UnknowEvent({data}) {
	const [t] = useTranslation();
	const isWarning = data?.data_fields?.is_warning;

	return (
		<Layout gap="sm" fullWidth nowrap fullHeight>
			<Layout direction="column" nowrap gap="md" alignItems="center" style={{width: '42px'}}>
				<Layout>
					{getIconByType(data.feed_type)}
				</Layout>
				<Layout>
					{
						isWarning && (
							<Layout>
								<WarningIcon color="warning" />
							</Layout>
						)
					}
				</Layout>
			</Layout>
			<Layout direction="column" fullWidth nowrap>
				<Layout direction="row" fullWidth nowrap>
					<Layout direction="column" fullWidth nowrap gap="xs">
						<Layout direction="row" fullWidth alignItems="center" justifyContent="space-between">
							<Text variant="h3">{getTitleByType(data.feed_type)}</Text>
							<Text color="textSecondary" variant="body1">
								{
									getDateByCustomFormat(data.created_at, DATE_FORMATS.DD_MMM_YYYY_HH_mm)
								}
							</Text>
						</Layout>
						<Layout fullWidth direction="row" nowrap>
							<Layout fullWidth gap="none">
								<Layout
									direction="row"
									justifyContent="space-between"
									fullWidth
									alignItems="center"
									gap="none"
								>
									<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
										{data.data_fields?.actor?.name}
									</Text>
								</Layout>
							</Layout>
						</Layout>
						<Layout direction="row" nowrap>
							<EventDescription
								description={`${t('Unknown event ')}, ${t('type')}: ${data.feed_type}`}
							/>
						</Layout>
						<Layout direction="row" fullWidth style={{gap: '8px'}}>
						</Layout>
					</Layout>
				</Layout>
			</Layout>
		</Layout>
	);
}

UnknowEvent.propTypes = {
	data: PropTypes.object,
};

export default UnknowEvent;
