import {
	count,
	MESSAGE_CHAR_LIMIT_EMOJI,
	MESSAGE_CHAR_LIMIT_PLAIN,
	PLACEHOLDER,
	PLACEHOLDER_CHAR_RESERVE,
} from './sms';

export function segmentsCount(str) {
	return count(str).messages;
}

export function isUCS2(str) {
	return count(str).encoding === 'UTF16';
}

export function charactersLeft(str) {
	if (!str) return MESSAGE_CHAR_LIMIT_PLAIN;

	const userNameRegex = new RegExp(PLACEHOLDER.USER_NAME, 'g');
	const phoneRegex = new RegExp(PLACEHOLDER.PHONE_NUMBER, 'g');
	const companyRegex = new RegExp(PLACEHOLDER.COMPANY_NAME, 'g');
	const userNamePlaceholders = (str.match(userNameRegex) || []).length;
	const phoneNumberPlaceholders = (str.match(phoneRegex) || []).length;
	const companyNamePlaceholders = (str.match(companyRegex) || []).length;

	str = str
		.replace(userNameRegex, '')
		.replace(phoneRegex, '')
		.replace(companyRegex, '')

		.replace('\t', '  ');

	const msgDetails = count(str);

	const placeholderUserName = PLACEHOLDER_CHAR_RESERVE[PLACEHOLDER.USER_NAME];
	const placeholderPhoneNumber = PLACEHOLDER_CHAR_RESERVE[PLACEHOLDER.PHONE_NUMBER];
	const placeholderCompanyName = PLACEHOLDER_CHAR_RESERVE[PLACEHOLDER.COMPANY_NAME];

	let maxLength = MESSAGE_CHAR_LIMIT_PLAIN;
	let msgLength = str.length;

	if (msgDetails.encoding === 'UTF16') {
		maxLength = MESSAGE_CHAR_LIMIT_EMOJI;
	}

	if (userNamePlaceholders) {
		msgLength += placeholderUserName;
	}

	if (phoneNumberPlaceholders) {
		msgLength += placeholderPhoneNumber;
	}

	if (companyNamePlaceholders) {
		msgLength += placeholderCompanyName;
	}

	return maxLength - msgLength;
}
