import React from 'react';
import PropTypes from 'prop-types';
import sc from 'styled-components';
import {truncate} from 'lodash';

import {Text, Tooltip} from '@phonectas/unifon-ui-kit';
import {StyledLabel} from '../TextDivWithLabel/styled';

const Wrapper = sc.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

function TextWithLabel({
	text,
	label,
	textColor = undefined,
	toUpperCase = false,
	truncated = false,
}) {
	const truncatedText = truncate(text, {length: 20});
	const isTextNa = text === 'N/A';
	return (
		<Wrapper>
			{
				truncated ? (
					<>
						<StyledLabel>{label}</StyledLabel>
						<Tooltip
							show={isTextNa ? false : undefined}
							text={
								<span>
									<Text style={{fontSize: '14px'}} variant="body">{text}</Text>
								</span>
							}
						>
							<div>
								<Text style={{color: textColor, cursor: isTextNa ? undefined : 'pointer'}}>
									{truncatedText}
								</Text>
							</div>
						</Tooltip>
					</>
				) : (
					<>
						<StyledLabel>{label}</StyledLabel>
						<Text style={{color: textColor}}>
							{toUpperCase ? text.toUpperCase() : text }
						</Text>
					</>
				)
			}
		</Wrapper>
	);
}

TextWithLabel.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	label: PropTypes.string,
	textColor: PropTypes.string,
	toUpperCase: PropTypes.bool,
	truncated: PropTypes.bool,
};

export default TextWithLabel;
