import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Layout, Text, Button, TextField} from '@phonectas/unifon-ui-kit';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

function CommentEvent({activeCustomer}) {
	const [t] = useTranslation();
	const {addEventComment, fetchAccountFeed} = useThreeSixtyStore();
	const [comment, setComment] = useState('');

	const handleAddComment = useCallback(() => {
		addEventComment(activeCustomer, comment, () => {
			fetchAccountFeed(activeCustomer);
			setComment('');
		});
	}, [activeCustomer, comment, addEventComment, fetchAccountFeed]);

	return (
		<Layout direction="column" fullWidth gap="sm">
			<Layout direction="row" style={{marginTop: '20px'}} justifyContent="space-between" fullWidth>
				<Text color="textSecondary">{t('Add comment')}</Text>
				<Text color="textSecondary">{`${comment.length}/480`}</Text>
			</Layout>
			<Layout direction="row" nowrap fullWidth>
				<TextField
					fullWidth
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					placeholder={t('Text')}
					// style={{height: '50px'}}
				/>
				<Button
					variant="primary"
					color="success"
					disabled={!comment}
					onClick={handleAddComment}
				>
					{t('Send')}
				</Button>
			</Layout>
		</Layout>
	);
}

CommentEvent.propTypes = {
	activeCustomer: PropTypes.string,
};

export default CommentEvent;
