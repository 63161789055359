import React, {useMemo} from 'react';
import {useLocation} from 'react-router-dom/dist';
import CasesTable from '../components/cases/CasesTable';

import {useSmallScreen} from 'common/hooks/useScreenSize';
import useBMOStore from 'common/stores/BMO';
// import useUserStore from 'common/stores/user';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';
import Case from './Case';
import AdaptiveDrawer from 'common/components/AdaptiveDrawer';
import {Divider, Layout, Page} from '@phonectas/unifon-ui-kit';

function CasesTab() {
	const location = useLocation();
	const isSmallScreen = useSmallScreen();
	const {cases, activeCase} = useBMOStore((state) => ({
		cases: state.BMO?.ticketsList,
		activeCase: state.BMO?.ticketById,
	}));
	// const user = useUserStore((state) => state.user?.name);
	const isLoading = useBMOStore((state) => state.BMO.isLoading);
	const activeCaseId = activeCase?.id;

	const caseContent = useMemo(() => {
		return (
			location?.search !== '' && (
				<>
					<Layout direction="column" fullWidth nowrap>
						<Page.Section fullHeight fullWidth>
							{
								cases !== undefined && (
									<Case data={cases} />
								)
							}
						</Page.Section>
					</Layout>
				</>
			)
		);
	}, [cases, location?.search]);

	return (
		<Layout direction="row" nowrap fullHeight fullWidth style={{overflow: 'hidden'}}>
			<Layout direction="column" style={{minWidth: '65%'}} fullWidth nowrap>
				<Page.Section style={{overflow: 'hidden'}} fullWidth fullHeight>
					{
						cases !== undefined && (
							<CasesTable
								data={cases}
								activeId={location?.search}
							/>
						)
					}
				</Page.Section>
			</Layout>
			{
				activeCaseId !== undefined && (
					<Divider vertical dark />
				)
			}
			{
				isSmallScreen && activeCaseId !== undefined ?
					<AdaptiveDrawer setWidth={900}><>{caseContent}</></AdaptiveDrawer> :
					<>
						{caseContent}
					</>
			}
			<LoaderPortal isLoading={isLoading} />
		</Layout>
	);
}

export default CasesTab;
