import React, {useCallback} from 'react';
import useUserStore from 'common/stores/user';
import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import {getBnSettupUrl} from 'utils/div';

import UserInfo from 'common/components/UserInfo';
import {IconButton, Layout, Page, Text} from '@phonectas/unifon-ui-kit';
import NewCopyIcon from 'common/components/icons/NewCopyIcon';

function UserProfile() {
	const user = useUserStore((state) => state.user);
	const [t] = useTranslation();
	const url = getBnSettupUrl();
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const handleCopyUrl = useCallback(() => {
		copyToClipboard(url);
	}, [url, copyToClipboard]);

	return (
		<>
			<Page.Section>
				<Layout direction="column" nowrap autoOverflow alignItems="center">
					<UserInfo data={user} />
				</Layout>
			</Page.Section>
			<Page.Section>
				<Layout fullWidth direction="column" gap="md">
					<Layout direction="column" nowrap autoOverflow alignItems="center">
						<Text variant="h1">Unifon 360</Text>
					</Layout>
					<Layout direction="row" gap="sm" nowrap autoOverflow alignItems="center">
						<div>
							<Text variant="body1">{t('Setting up Bedriftsnett:')}</Text>
						</div>
						<Layout nowrap gap="xs" alignItems="center">
							<Text variant="body1">{url}</Text>
							<IconButton onClick={handleCopyUrl} size="xxs">
								<NewCopyIcon />
							</IconButton>
						</Layout>
					</Layout>
				</Layout>
			</Page.Section>
		</>
	);
}

export default UserProfile;
