/**
 *
 * @param {string} endPoint endpoint of the url
 * @return {string} url and endpoint
 */

export const getApiUrl = (endPoint) => {
	let baseUrl = '';
	switch (window.location.hostname) {
	// prod
	case 'ks.unifon.no':
		baseUrl = 'https://ks.unifon.no/api/v1/';
		break;
		// pre prod
	case 'ks.pp.unifon.no':
		baseUrl = 'https://ks.pp.unifon.no/api/v1/';
		break;
	// staging
	case 'ks.staging.unifon.no':
		baseUrl = 'https://ks.staging.unifon.no/api/v1/';
		break;
		// localhost
	case 'localhost':
		baseUrl = 'https://ks.staging.unifon.no/api/v1/';
		break;
	default:
		throw new Error('Unknown hostname');
	}
	return `${baseUrl}${endPoint}`;
};

export const bmoApiUrl = getApiUrl('telenor/');
export const threeSixtyApiUrl = getApiUrl('threesixty/');
export const devDocsApiUrl = getApiUrl('devdocusers/');
export const devDocsApiUrlCreate = getApiUrl('devdocusers');
export const sendSmsApiUrl = getApiUrl('sendsms');
