import React, {useCallback, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Layout, ListItem, Select, Text} from '@phonectas/unifon-ui-kit';
import useBMOStore from 'common/stores/BMO';

SortByCases.propTypes = {
	onSort: PropTypes.func.isRequired,
	sortByValue: PropTypes.object,
};

/**
 * @callback SortByCases
 * @param {string} value
 * @return {void}
 */

/**
 * @module containers/BMO/components/SortBy
 * @param {SortByCasesCallback} onSort
 * @return {JSX.Element}
 * @constructor
 */
function SortByCases({onSort, sortByValue}) {
	const [t] = useTranslation();
	const {getStatuses} = useBMOStore();
	const {statuses} = useBMOStore((state) => ({
		statuses: state.BMO.statuses,
	}));
	const items = useMemo(() => {
		const defaultItems = [
			{key: 'all', label: t('All'), value: 20},
			{key: 'open', label: t('Open'), value: 21},
		];

		if (!statuses) {
			return defaultItems;
		}
		const statusItems = Object.keys(statuses).map((key) => ({
			key,
			label: t(key),
			value: statuses[key],
		}));

		return [...defaultItems, ...statusItems];
	}, [statuses, t]);
	const handleSort = useCallback((item) => {
		onSort(item);
	}, []);

	useEffect(() => {
		if (!statuses) {
			getStatuses();
		}
	}, [getStatuses, statuses]);
	return (
		<Layout nowrap gap="sm" alignItems="center">
			<Text color="textSecondary" truncate={1} variant="body1">
				{t('Sort by')}:
			</Text>
			<Select
				alt
				onChange={handleSort}
			>
				{items.map((item) => (
					<ListItem key={item.key} value={item} primaryText={item.label} selected={sortByValue.key === item.key}>
						{item.label}
					</ListItem>
				))}
			</Select>
		</Layout>
	);
}

export default React.memo(SortByCases);
