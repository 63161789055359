import React, {useCallback, useState} from 'react';

// import useThreeSixtyStore from 'common/stores/ThreeSixty';
import RenderItemDetails from './RenderItemDetails';
import {StyledSectionDiv} from '../components/customer/styled';
import {Layout, Table, Text} from '@phonectas/unifon-ui-kit';
import SearchWidget from 'common/components/SearchWidget';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {LIMIT_PAYLOAD} from 'services/constants';

function ResponsibleTab(props) {
	const [t] = useTranslation();
	const {majorResponsibleSearch, majorResponsibleList} = useThreeSixtyStore();
	const majorCustomerList = useThreeSixtyStore((state) => state?.customerResponibles);
	const majorList = majorCustomerList?.customer_resps;
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const handleRowClick = useCallback((index, item) => {
		setSelectedIndex(index);
		setSelectedItem(item);
		majorResponsibleSearch(item?.entra_id);
	}, [majorResponsibleSearch, setSelectedItem]);
	const handleSearchQuery = useCallback((value) => {
		majorResponsibleList(LIMIT_PAYLOAD, null, value);
	}, [majorResponsibleList]);
	const handleCrearQuery = useCallback(() => {
		setSearchQuery('');
		majorResponsibleList(LIMIT_PAYLOAD);
	}, [majorResponsibleList]);
	const renderItem = useCallback((item, index) => (
		<Table.Row
			key={index}
			index={index}
			onClick={() => handleRowClick(index, item)}
			selected={index === selectedIndex}
		>
			<Table.Cell>{item?.name}</Table.Cell>
			<Table.Cell>{item?.mobiles}</Table.Cell>
			<Table.Cell>{item?.customers_count}</Table.Cell>
		</Table.Row>
	), [selectedIndex, handleRowClick]);

	return (
		<Layout nowrap fullWidth fullHeight style={{overflow: 'hidden'}}>
			<StyledSectionDiv
				style={{
					maxWidth: '40%',
					display: 'flex',
					flexWrap: 'nowrap',
					flexDirection: 'column',
					height: '100%',
					overflow: 'hidden',
				}}
			>
				<Layout direction="row" fullWidth>
					<SearchWidget
						placeholder="Search for major customer responsible"
						fullWidth
						value={searchQuery}
						onChange={handleSearchQuery}
						clearSearch={handleCrearQuery}

					/>
				</Layout>
				<Layout direction="row" fullWidth fullHeight>
					<Table
						items={majorList || []}
						renderItem={renderItem}
						style={{overflow: 'auto', paddingBottom: '100px'}}
					>
						<Table.Row>
							<Table.Cell>{t('Name')}</Table.Cell>
							<Table.Cell>{t('Mobiles')}</Table.Cell>
							<Table.Cell width={50}>{t('Quantity')}</Table.Cell>
						</Table.Row>
					</Table>

				</Layout>
			</StyledSectionDiv>
			{
				selectedItem !== null ? (
					<StyledSectionDiv style={{display: 'flex', height: '100%'}}>
						<Layout fullWidth gap="sm">
							<RenderItemDetails />
						</Layout>
					</StyledSectionDiv>
				) :
					(
						<StyledSectionDiv>
							<Layout fullWidth gap="sm">
								<Text variant="h5">
									Favorites major customer responsible
								</Text>
							</Layout>
						</StyledSectionDiv>
					)
			}
		</Layout>
	);
}

export default ResponsibleTab;
