import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {
	Divider,
	Layout,
	Text,
} from '@phonectas/unifon-ui-kit';
// import NewCopyIcon from 'common/components/icons/NewCopyIcon';
import {formatInfo, getSatisfactionDetails} from 'utils/div';
// import {useSmallScreen} from 'common/hooks/useScreenSize';
// import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
import TextWithLabel from 'common/components/TextWithLabel';
import NewOkIcon from 'common/components/icons/NewOkIcon';
// import useThreeSixtyStore from 'common/stores/ThreeSixty';

function SmallViewDetails({data}) {
	const [t] = useTranslation();
	const accountDetails = data?.account_details;
	const isOrgNumber = accountDetails?.vat_number;
	const mobiles = accountDetails?.products.mobiles_active_count;
	const satisfaction = accountDetails?.satisfaction_rating;
	const satisfactionRating = getSatisfactionDetails(satisfaction);
	// const isNumberOfEmployees = accountDetails?.employers_count;
	const isCustomerResponsible = accountDetails?.customer_responsible?.name;

	return (
		<>
			<Divider fullWidth />
			<Layout
				fullWidth
				direction="row"
				style={{padding: '16px'}}
				justifyContent="space-between"
			>
				<Layout>
					{
						isCustomerResponsible && (
							<TextWithLabel label={t('Customer responsible')} text={accountDetails?.customer_responsible?.name} />
						)
					}
					{
						isOrgNumber && (
							<TextWithLabel label={t('Org. number')} text={formatInfo(accountDetails?.vat_number)} />
						)
					}
					{
						mobiles && (
							<TextWithLabel label={t('Mobiles')} text={mobiles} />
						)
					}
					{
						satisfaction && (
							<TextWithLabel
								label={t('Satisfaction')}
								textColor={satisfactionRating.colorCode}
								text={satisfactionRating.text}
								toUpperCase
							/>
						)
					}
				</Layout>
				<Layout
					direction="column"
					gap="xs"
				>
					<Layout alignItems="center" style={{gap: '5px'}}>
						<NewOkIcon color={accountDetails?.is_vip_customer ? undefined : '#9798A9'} />
						<Text>VIP</Text>
					</Layout>
					<Layout alignItems="center" style={{gap: '5px'}}>
						<NewOkIcon color={accountDetails?.is_major_customer ? undefined : '#9798A9'} />
						<Text>{t('Major customer')}</Text>
					</Layout>
				</Layout>
			</Layout>
		</>
	);
}

SmallViewDetails.propTypes = {
	data: PropTypes.object,
};

export default SmallViewDetails;
