import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {IconButton, Table, Text, TrashBinIcon} from '@phonectas/unifon-ui-kit';

function EmailNotificationTableRow({index, item, onDelete}) {
	const handleDelete = useCallback(() => {
		onDelete(index);
	}, [onDelete, index]);

	return (
		<Table.Row
			index={index}
			key={index}
		>
			<Table.Cell>{item}</Table.Cell>
			<Table.Cell>
				<Text>
					<IconButton
						disabled={!onDelete}
						color="error"
						onClick={handleDelete}
					>
						<TrashBinIcon />
					</IconButton>
				</Text>
			</Table.Cell>
		</Table.Row>
	);
}

EmailNotificationTableRow.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.string.isRequired,
	onDelete: PropTypes.func,
};

export default EmailNotificationTableRow;
