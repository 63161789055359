import React, {useCallback, useState, useMemo, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import {useTranslation} from 'react-i18next';
import SortByCases from '../SortByCases';
import useUserStore from 'common/stores/user';
import useBMOStore from 'common/stores/BMO';

import SearchWidget from 'common/components/SearchWidget';
import {Layout, Table, Text, Checkbox} from '@phonectas/unifon-ui-kit';
import {StyledCenterCenterLayout} from 'common/styled';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import {LIMIT_PAYLOAD} from 'services/constants';
import {debounce} from 'lodash';


/**
 * @module containers/BMO/cases/CasesTable
 * @param  {Object}	data
 * @return {JSX.Element}
 * @constructor
 */
function CasesTable({activeId}) {
	const [t] = useTranslation();
	const user = useUserStore((state) => state.user);
	const hasFetchedCases = useRef(false);
	const [query, setQuery] = useState('');
	const DEFAULT_SORT = {
		key: 'open',
		label: t('Open'),
		value: 21,
	};
	const [sortByValue, setSortByValue] = useState(DEFAULT_SORT);
	const [myCases, setMyCases] = useState(false);
	const {fetchAllCases} = useBMOStore();
	const {caseslist, ticketId} = useBMOStore((state) => ({
		statuses: state.BMO?.statuses,
		caseslist: state.BMO?.ticketsList,
		ticketId: state.BMO?.ticketById,
	}));
	const currentId = parseInt(ticketId?.id);
	const navigate = useNavigate();
	const isSmallScreen = useSmallScreen('(max-width:1300px)');
	const debouncedFetchList = useMemo(() => debounce((searchValue) => {
		setQuery(searchValue);
	}, 500), [fetchAllCases]);
	const onClick = useCallback((item) => {
		navigate(`/BMO/cases?id=${item.id}`);
	}, [navigate]);
	const onToggleMyCases = useCallback(() => {
		setMyCases((prev) => !prev);
	}, []);
	const handleSortChange = useCallback((value) => {
		setSortByValue(value);
	}, []);

	const clearSearch = useCallback(() =>{
		setQuery('');
		setSortByValue([5]);
		setMyCases(false);
	}, []);
	const handleSearchChange = useCallback((value) => {
		if (typeof value === 'string') {
			debouncedFetchList(value);
		} else {
			setQuery('');
		}
	}, [debouncedFetchList]);
	const onLoadMore = useCallback((size) => {
		fetchAllCases(size + LIMIT_PAYLOAD, query, sortByValue, myCases ? user.catalogID : '');
	}, [fetchAllCases, query, sortByValue, myCases, user]);

	useEffect(() => {
		let statusIds = [];
		if (sortByValue.value === 20) {
			statusIds = [];
		} else if (sortByValue.value === 21) {
			statusIds = [1, 3, 4, 5];
		} else {
			statusIds = [sortByValue.value];
		}
		if (query) {
			fetchAllCases(LIMIT_PAYLOAD, query, [], myCases ? user.catalogID : '');
		} else {
			fetchAllCases(
				LIMIT_PAYLOAD,
				query,
				statusIds,
				myCases ? user.catalogID : '',
			);
		}
	}, [fetchAllCases, query, sortByValue, myCases, user]);
	const isDataInFilter = caseslist?.length === 0;
	const renderItem = useCallback((id, item, index) => (
		<Table.Row
			key={item.id}
			index={index}
			onClick={() => onClick(item)}
			selected={item.id === currentId}
		>
			<Table.Cell>{item?.id}</Table.Cell>
			<Table.Cell>{item?.status}</Table.Cell>
			<Table.Cell>{item?.reporter}</Table.Cell>
			<Table.Cell>
				<Text truncate={2}>
					{item?.unifon_subject}
				</Text>
			</Table.Cell>
			<Table.Cell>{item?.zendeskRef}</Table.Cell>
			<Table.Cell>{getDateByCustomFormat(item?.createdAt, DATE_FORMATS.DD_MM_YYYY)}</Table.Cell>
		</Table.Row>
	), [onClick, currentId]);

	useEffect(() => {
		if (!hasFetchedCases.current && ticketId?.id && !caseslist.some((item) => item.id === currentId)) {
			fetchAllCases(LIMIT_PAYLOAD, ticketId.id, []);
			hasFetchedCases.current = true;
		}
	}, [ticketId, caseslist, currentId, fetchAllCases]);

	return (
		<Layout fullHeight fullWidth>
			<Layout
				direction="row"
				fullWidth
				justifyContent="end"
				gap="xxl"
				alignItems="center"
			>
				<Layout direction="column">
					<SortByCases
						sortByValue={sortByValue}
						onSort={handleSortChange}
					/>
				</Layout>
				<Layout direction="column" justifyContent="start">
					{
						user?.name && (
							<Checkbox
								label={t('My cases')}
								toggle
								big
								checked={myCases}
								onChange={onToggleMyCases}
							/>
						)
					}
				</Layout>
				<Layout direction="column">
					<SearchWidget
						onChange={handleSearchChange}
						placeholder={t('Search...')}
						clearSearch={clearSearch}
						searchQuery={query}
					/>
				</Layout>
			</Layout>
			<Layout fullWidth nowrap fullHeight>
				{
					isDataInFilter ?
						<StyledCenterCenterLayout>
							<Text variant="h4">
								{/* {t('No cases in this catogory')} */}
								{t(`No cases available in this category`)}
							</Text>
						</StyledCenterCenterLayout> :
						<Layout grow fullWidth fullHeight direction="column" nowrap>
							<Table
								style={{paddingBottom: '20px'}}
								items={caseslist || []}
								renderItem={(item, index) => renderItem(item.id, item, index)}
								virtualized
								fullHeight
								onScrollDown={onLoadMore}
							>
								<Table.Row>
									<Table.Cell width={isSmallScreen ? 90 : undefined}>{t('ID')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 110 : undefined}>{t('status')}</Table.Cell>
									<Table.Cell>{t('Reporter')}</Table.Cell>
									<Table.Cell>{t('Subject')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 85 : undefined}>{t('zendesk ref')}</Table.Cell>
									<Table.Cell width={isSmallScreen ? 95 : undefined}>{t('Created at')}</Table.Cell>
								</Table.Row>
							</Table>
						</Layout>
				}
			</Layout>
		</Layout>
	);
}

CasesTable.propTypes = {
	activeId: PropTypes.string,
};

export default React.memo(CasesTable);
