import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {Layout} from '@phonectas/unifon-ui-kit';
import TextWithLabel from 'common/components/TextWithLabel';
import {formatInfo, getSatisfactionDetails} from 'utils/div';

function CustomerCompanyInfo({data}) {
	const [t] = useTranslation();
	const accDeatails = data?.account_details;
	const satisfaction = getSatisfactionDetails(accDeatails?.satisfaction_rating);

	return (
		<Layout direction="row" fullWidth style={{padding: '16px', justifyContent: 'space-evenly'}}>
			<Layout direction="column" gap="md" style={{margin: '0 auto 0 0'}}>
				<TextWithLabel label={t('Group agreement')} text={formatInfo(accDeatails?.group_agreement)} />
				<TextWithLabel label={t('Org. number')} text={formatInfo(accDeatails?.vat_number)} />
			</Layout>
			<Layout direction="column" gap="md" style={{margin: '0 auto 0 0'}}>
				<TextWithLabel truncated label={t('Trade code')} text={formatInfo(accDeatails?.industry?.desc)} />
				<TextWithLabel label={t('Number of employees')} text={formatInfo(accDeatails?.employers_count)} />
			</Layout>
			<Layout direction="column" gap="md" style={{margin: '0 auto 0 0'}}>
				<TextWithLabel label={t('Provider code')} text={formatInfo(accDeatails?.provider_code)} />
				<TextWithLabel label={t('Sales rep.')} text={formatInfo(accDeatails?.sales_rep)} />
			</Layout>
			<Layout direction="column" gap="md" style={{margin: '0 auto 0 0'}}>
				<TextWithLabel
					label={t('Satisfaction')}
					textColor={satisfaction.colorCode}
					text={satisfaction.text}
					toUpperCase
				/>
			</Layout>
		</Layout>
	);
}

CustomerCompanyInfo.propTypes = {
	data: PropTypes.object,
};

export default CustomerCompanyInfo;
