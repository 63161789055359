import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import {isValidPhone} from 'utils/validators';

import {Button, Layout, ListItem, Select, TextArea, TextField} from '@phonectas/unifon-ui-kit';
import LanguageSelector from 'common/components/LanguageSelector';
import i18n from 'common/i18n';
import MessageLength from 'common/components/MessageLength';

function SmsModule(props) {
	const [t] = useTranslation();
	const {sendSms} = useThreeSixtyStore();
	const {user} = useThreeSixtyStore((state) => ({
		user: state.activeRow,
	}));
	const [text, setText] = useState('');
	const [recipient, setRecipient] = useState('');
	const [language, setLanguage] = useState(i18n?.language || 'no');
	const [template, setTemplate] = useState(null);
	const templates = useMemo(() => [
		{
			label: 'pin/puk',
			id: 0,
			message: {
				no: `Hei ${user?.name || ''}, din Pin/Puk er ${user?.pin || ''} / ${user?.puk || ''}.`,
				en: `Hello ${user?.name || ''}, your Pin/Puk is ${user?.pin || ''} / ${user?.puk || ''}.`,
			},
		},
	], [user]);
	const handleResetSms = useCallback(() => {
		setText('');
		setRecipient('');
		setTemplate(null);
	}, []);
	const handleTextChange = useCallback((e) => {
		setText(e.target.value);
	}, []);
	const handleSetRecipient = useCallback((e) => {
		setRecipient(e.target.value);
	}, []);
	const onSendSms = useCallback(() => {
		sendSms(recipient, text, () => {
			handleResetSms();
		});
	}, [sendSms, recipient, text, handleResetSms]);

	const onTemplateSelect = useCallback((value) => {
		if (value && value.id !== undefined) {
			setTemplate(value);
			if (language === 'no') {
				setText(templates[value.id].message.no);
			} else {
				setText(templates[value.id].message.en);
			}
		} else {
			setTemplate(null);
			setText('');
		}
	}, [language, templates]);
	const handleLanguageChange = useCallback((lang) => {
		setLanguage(lang);
	}, []);

	useEffect(() => {
		if (template !== null) {
			if (language === 'no') {
				setText(templates[template.id].message.no);
			} else {
				setText(templates[template.id].message.en);
			}
		}
	}, [language, template, templates]);

	useEffect(() => {
		if (user) {
			setRecipient(user.phone_number);
		}
	}, [user]);

	const mobileValidator = useCallback((val) => !val || isValidPhone(val), []);

	return (
		<Layout direction="column" fullWidth gap="sm">
			<Layout direction="row" fullWidth>
				<LanguageSelector
					noAuto
					lang={language}
					onChange={handleLanguageChange}
					isDisabled={template === null}
				/>
			</Layout>
			<Layout direction="row" nowrap alignItems="end" fullWidth>
				<TextField
					fullWidth
					value={recipient}
					onChange={handleSetRecipient}
					label={t('Recipient')}
					validator={mobileValidator}
				/>
				<Select
					fullWidth
					label={t('Template')}
					onChange={onTemplateSelect}
					value={template?.label}
				>
					<ListItem
						value={null}
						primaryText={t('Select template')}
						selected={template === null}
					/>
					{
						templates.map((temp, index) => {
							return (
								<ListItem
									key={index}
									value={temp}
									primaryText={temp.label}
									selected={temp.label === template?.label}
								/>
							);
						})
						// templates.map((temp, index) => (
						// 	<ListItem
						// 		key={index}
						// 		value={temp}
						// 		primaryText={temp.label}
						// 		selected={index === template?.id}
						// 	/>
						// ))
					}
				</Select>
			</Layout>
			<MessageLength message={text} />
			<Layout direction="row" fullWidth>
				<TextArea
					fullWidth
					value={text}
					onChange={handleTextChange}
					placeholder={t('Text')}
					style={{height: '160px'}}
				/>
			</Layout>
			<Layout direction="row" nowrap justifyContent="end" fullWidth>
				<Button
					variant="secondary"
					color="error"
					onClick={handleResetSms}
				>
					{t('Clear')}
				</Button>
				<Button
					variant="primary"
					color="success"
					disabled={!text || !recipient}
					onClick={onSendSms}
				>
					{t('Send')}
				</Button>
			</Layout>
		</Layout>
	);
}
export default React.memo(SmsModule);
