import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

import {AutoSuggest, IconButton, Layout, ListItem, SearchIcon, Text} from '@phonectas/unifon-ui-kit';
import {debounce} from 'lodash';
import Highlighter from 'react-highlight-words';
import {ROUTES} from '../../../../../common/constants';
import {useNavigate} from 'react-router-dom';
import {isValidPhone} from 'utils/phoneNumber';
import {serviceStatusesColor} from 'utils/div';

function UserNotFound({numb}) {
	const [t] = useTranslation();
	const [value, setValue] = useState('');
	const {fetchList, fetchUser} = useThreeSixtyStore();
	const navigate = useNavigate();
	const accountsList = useThreeSixtyStore((state) => state.accounts);
	const accounts = accountsList?.accounts;
	const debouncedFetchList = debounce((searchValue) => {
		fetchList(10, searchValue, 'serviceStatusAsc');
	}, 500);

	const handleChange = useCallback((value) => {
		if (typeof value === 'string') {
			setValue(value);
			debouncedFetchList(value);
		} else {
			setValue('');
		}
	}, [debouncedFetchList]);
	const onClick = useCallback((item) => {
		if (isValidPhone(value)) {
			fetchUser(value, item, () => {
				navigate(`${ROUTES.THREE_SIXTY}/${item}`);
			});
		} else {
			navigate(`${ROUTES.THREE_SIXTY}/${item}`);
		}
	}, [navigate, fetchUser, value]);

	const renderOption = useCallback(({index, style, data: {collection}}) => {
		const item = collection[index];
		return (
			<ListItem
				key={index}
				style={style}
				value={item.id}
				onClick={onClick}
				color="primary"
				primaryText={
					<Text variant="h5">
						<Highlighter
							autoEscape
							searchWords={[value]}
							textToHighlight={item.name}
						/>
					</Text>
				}
				secondaryText={
					<>
						{
							item?.user_details?.phone_number ? (
								<Layout fullWidth justifyContent="space-between" nowrap>
									<Text color="textSecondary" variant="caption">
										{item.name}
									</Text>
									<Text
										style={{
											color: serviceStatusesColor(status).priColor,
										}}
										variant="caption"
									>
										{serviceStatusesColor(status).text}
									</Text>
								</Layout>
							) : (
								<Text color="textSecondary" variant="caption">
									{`${t('VAT')}: ${item.vat_number}`}
								</Text>
							)
						}

					</>
				}
			/>
		);
	}, [value, onClick, t]);

	return (
		<Layout style={{userSelect: 'none'}} fullWidth>
			<Layout style={{width: '40%'}}>
				<AutoSuggest
					placeholder={t('Search for customers')}
					fullWidth
					compact
					itemSize={50}
					onChange={handleChange}
					filterBy={['name']}
					collection={accounts || []}
					renderItem={renderOption}
					endAdornment={
						<IconButton>
							{!value ? <SearchIcon/> : null}
						</IconButton>
					}
					maxDropdownHeight={300}
					inputProps={{autoFocus: 'on'}}
				/>
			</Layout>
			<Layout fullWidth>
				<Text variant="h2">
					{t('User not found with number: {{numb}}', {numb})}
				</Text>
			</Layout>
		</Layout>
	);
}

UserNotFound.propTypes = {
	numb: PropTypes.string.isRequired,
};

export default UserNotFound;
