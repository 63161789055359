import i18n from 'common/i18n';

/**
 *
 * @param {*} info
 * @return {string} info or 'N/A'
 */
export function formatInfo(info) {
	return !info ? 'N/A' : info;
}

export function serviceStatuses(status) {
	switch (status) {
	case '100':
		return i18n.t('Under innportering');
	case '200':
		return i18n.t('Active');
	case '220':
		return i18n.t('Sperret - høyt forbruk');
	case '230':
		return i18n.t('Sperret');
	case '240':
		return i18n.t('Reservert');
	case '280':
		return i18n.t('Hard blocked');
	case '310':
		return i18n.t('Under utportering');
	case '400':
		return i18n.t('Terminert');
	case '410':
		return i18n.t('Utportert');
	default:
		return 'N/A';
	}
}

export function serviceStatusesColor(status) {
	switch (status) {
	case '100':
		return {
			text: i18n.t('Under innportering'),
			priColor: '#47AC45',
			secColor: '#2C305E',
			icon: 'underInportering',
		};
	case '200':
		return {
			text: i18n.t('Active'),
			priColor: '#47AC45',
			secColor: '#fff',
			icon: 'active',
		};
	case '220':
		return {
			text: i18n.t('Sperret - høyt forbruk'),
			priColor: '#EE5151',
			secColor: '#EE5151',
			icon: 'sperret',
		};
	case '230':
		return {
			text: i18n.t('Sperret'),
			priColor: '#EE5151',
			secColor: '#EE5151',
			icon: 'Sperret',
		};
	case '240':
		return {
			text: i18n.t('Reservert'),
			priColor: '#F7AB20',
			secColor: '#F7AB20',
			icon: 'reservert',
		};
	case '280':
		return {
			text: i18n.t('Hard blocked'),
			priColor: '#EE5151',
			secColor: '#EE5151',
			icon: 'sperret',
		};
	case '310':
		return {
			text: i18n.t('Under utportering'),
			priColor: '#EE5151',
			secColor: '#2C305E',
			icon: 'underUtportering',
		};
	case '400':
		return {
			text: i18n.t('Terminert'),
			priColor: '#EE5151',
			secColor: '#EE5151',
			icon: 'sperret',
		};
	case '410':
		return {
			text: i18n.t('Utportert'),
			priColor: '#9798A9',
			secColor: '#9798A9',
			icon: 'utPortert',
		};
	default:
		return {text: '', priColor: undefined, secColor: undefined, icon: undefined};
	}
}

export function getSatisfactionDetails(satisfaction) {
	let colorCode = '';
	let text = '';
	const satisfactionToInt = parseInt(satisfaction, 10);

	switch (true) {
	case satisfaction === null:
		text = i18n.t('Not rated');
		colorCode = '#2C305E';
		break;
	case satisfactionToInt >= 0 && satisfactionToInt <= 2:
		colorCode = '#EE5151';
		text = i18n.t('Very bad');
		break;
	case satisfactionToInt >= 3 && satisfactionToInt <= 4:
		colorCode = '#EE5151';
		text = i18n.t('Bad');
		break;
	case satisfactionToInt === 5:
		colorCode = '#DBAB42';
		text = i18n.t('Neutral');
		break;
	case satisfactionToInt >= 6 && satisfactionToInt <= 7:
		colorCode = '#47AC45';
		text = i18n.t('Good');
		break;
	case satisfactionToInt >= 8 && satisfactionToInt <= 10:
		colorCode = '#47AC45';
		text = i18n.t('Very good');
		break;
	}

	return {colorCode, text};
}

/**
 * Masks a string by replacing each character with the Unicode character &#9679;.
 * @param {string} str - The input string to mask.
 * @return {string} The masked string.
 */
export function maskString(str) {
	if (!str) {
		return '';
	}
	const value = str.toString();
	return value?.split('').map(() => '•︎').join('');
}

export function checkResponisble(respId, respEst) {
	if (respId) {
		return respId?.name;
	} else if (respEst) {
		return respEst;
	}
	return '';
}

export function nullCheck(value) {
	if (!value) {
		return '';
	}
	return value;
}

export function npsScrore(score) {
	let color = '';
	const scoreView = `${score}/10`;

	if (score >= 1 && score <= 6) {
		color = '#EE5151';
	} else if (score >= 7 && score <= 8) {
		color = '#F7AB20';
	} else if (score >= 9 && score <= 10) {
		color = '#47AC45';
	} else {
		return {score: 'N/A', color: undefined};
	}

	return {score, color, scoreView};
}

export function getBnSettupUrl() {
	let url = '';
	switch (window.location.hostname) {
	case 'ks.unifon.no':
		url = 'https://ks.unifon.no/Unifon360/n/${caller}';
		break;
	case 'ks.pp.unifon.no':
		url = 'https://ks.pp.unifon.no/Unifon360/n/${caller}';
		break;
	case 'localhost':
		url = 'https://localhost:3003/Unifon360/n/${caller}';
		break;
	case 'ks.staging.unifon.no':
		url = 'https://ks.staging.unifon.no/Unifon360/n/${caller}';
		break;
	default:
		url = 'https://ks.unifon.no/Unifon360/n/${caller}';
		break;
	}
	return url;
}
