import React, {useCallback, useState, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import Highlighter from 'react-highlight-words';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'common/constants';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import {AutoSuggest, IconButton, Layout, ListItem, SearchIcon, Text} from '@phonectas/unifon-ui-kit';
import {isValidPhone} from 'utils/phoneNumber';
import {serviceStatusesColor} from 'utils/div';

function ThreeSixtySearchBar({closeSearch}) {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const {fetchList, fetchUser} = useThreeSixtyStore();
	const [value, setValue] = useState('');
	const accountsList = useThreeSixtyStore((state) => state.accounts);
	const accounts = accountsList?.accounts;
	const debouncedFetchList = useMemo(() => debounce((searchValue) => {
		fetchList(10, searchValue, 'serviceStatusAsc');
	}, 500), [fetchList]);

	const handleChange = useCallback((value) => {
		if (typeof value === 'string') {
			setValue(value);
			debouncedFetchList(value);
		} else {
			setValue('');
		}
	}, [debouncedFetchList]);
	const onClick = useCallback((item) => {
		if (isValidPhone(value)) {
			fetchUser(value, item, () => {
				navigate(`${ROUTES.THREE_SIXTY}/${item}`);
			});
		} else {
			navigate(`${ROUTES.THREE_SIXTY}/${item}`);
		}
	}, [navigate, fetchUser, value]);

	const renderOption = useCallback(({index, style, data: {collection}}) => {
		const item = collection[index];
		const status = item?.user_details?.service_status.toString();

		return (
			<ListItem
				key={index}
				style={style}
				value={item.id}
				onClick={onClick}
				color="primary"
				primaryText={
					<Text variant="h5">
						<Highlighter
							autoEscape
							searchWords={[value]}
							textToHighlight={item?.user_details?.phone_number ? item?.user_details?.phone_number : item.name || item.vat_number}
						/>
					</Text>
				}
				secondaryText={
					<>
						{
							item?.user_details?.phone_number ? (
								<Layout fullWidth justifyContent="space-between" nowrap>
									<Text color="textSecondary" variant="caption">
										{item.name}
									</Text>
									<Text
										style={{
											color: serviceStatusesColor(status).priColor,
										}}
										variant="caption"
									>
										{serviceStatusesColor(status).text}
									</Text>
								</Layout>
							) : (
								<Text color="textSecondary" variant="caption">
									{`${t('VAT')}: ${item.vat_number}`}
								</Text>
							)
						}

					</>
				}
			/>
		);
	}, [value, t, onClick]);

	useEffect(() => {
		if (!accountsList) {
			fetchList();
		}
	}, [accountsList, fetchList]);

	useEffect(() => {
		if (closeSearch) {
			setValue('');
			fetchList();
		}
	}, [closeSearch, fetchList]);

	return (
		<>
			<AutoSuggest
				placeholder={t('Search for customers')}
				fullWidth
				compact
				itemSize={50}
				onChange={handleChange}
				// filterBy={['name', 'vat_number', 'id']}
				disableFiltering
				collection={accounts || []}
				renderItem={renderOption}
				endAdornment={
					<IconButton>
						{!value ? <SearchIcon/> : null}
					</IconButton>
				}
				maxDropdownHeight={300}
				inputProps={{autoFocus: 'on'}}
			/>
		</>
	);
}

ThreeSixtySearchBar.propTypes = {
	closeSearch: PropTypes.bool,
};

export default React.memo(ThreeSixtySearchBar);
