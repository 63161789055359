import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {ListItem, Select} from '@phonectas/unifon-ui-kit';

function LanguageSelector({lang = 'no', onChange, noAuto = false, isDisabled = false}) {
	const [t] = useTranslation();
	const handleChange = useCallback((value) => {
		onChange(value);
	}, [onChange]);

	return (
		<Select
			label={t('Language')}
			onChange={handleChange}
			value={lang}
			disabled={isDisabled}
		>
			{!noAuto && (
				<ListItem
					value="auto"
					color="primary"
					selected={lang === 'auto'}
					primaryText={'Auto'}
				/>
			)}
			<ListItem
				value="no"
				color="primary"
				selected={lang === 'no'}
				primaryText={t('Norwegian')}

			/>
			<ListItem
				value="en"
				color="primary"
				selected={lang === 'en'}
				primaryText={t('English')}
			/>
		</Select>
	);
}

LanguageSelector.propTypes = {
	lang: PropTypes.string,
	onChange: PropTypes.func,
	noAuto: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default LanguageSelector;
