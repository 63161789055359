/**
 * @param {String} phone
 * @return {boolean}
 */
// export function isValidPhone(phone) {
// 	// +47<8 digits>
// 	// or (without leading +47)
// 	// <8 digits>
// 	// the 8 digits have to start with 2,3,4,5,6,7 or 9
// 	return /^\+?(\d+)$/.test(phone);
// }

export function isValidPhone(phone) {
	// +47<8 digits> or <8 digits> starting with 2,3,4,5,6,7 or 9
	return /^(\+47)?[2345679]\d{7}$/.test(phone);
}
