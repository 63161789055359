import React from 'react';
import {LinearProgress, styled} from '@mui/material';

const StyledLinearProgress = styled(LinearProgress)(({theme}) => ({
	'&.MuiLinearProgress-root': {
		backgroundColor: '#2C305E',
		border: 'none',
	},
	'& .MuiLinearProgress-bar': {
		backgroundColor: '#fff',
	},
}));

const ColoredLinearProgress = (props) => {
	return <StyledLinearProgress variant='indeterminate' {...props} />;
};

export default ColoredLinearProgress;
